//import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString:
      'InstrumentationKey=dc850aa7-a0bd-43d0-9f1c-9990397e90da;IngestionEndpoint=https://eastus2-3.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus2.livediagnostics.monitor.azure.com/;ApplicationId=62f46a86-0169-40cb-b4a3-c01eb67a948a',
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
    disableAjaxTracking: false,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true
  }
});
appInsights.loadAppInsights();

// To add Telemetry data use the following code and import ITelemetryItem

// appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
//   env.tags = env.tags || {};
//   env.tags['ai.cloud.role'] = '<app-role-frontend>';
//   //custom props
//   env.data = env.data || {};
//   env.data['ms-appName'] = '<frontend-name>';
//   env.data['ms-user'] = '<frontend-auth-user>';
//   env.data['ms-userid'] = '<frontend-auth-userid>';
// });

export { reactPlugin, appInsights };
